// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-check-in-tsx": () => import("/opt/build/repo/src/pages/check-in.tsx" /* webpackChunkName: "component---src-pages-check-in-tsx" */),
  "component---src-pages-import-tsx": () => import("/opt/build/repo/src/pages/import.tsx" /* webpackChunkName: "component---src-pages-import-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-tsx": () => import("/opt/build/repo/src/pages/list.tsx" /* webpackChunkName: "component---src-pages-list-tsx" */),
  "component---src-pages-login-tsx": () => import("/opt/build/repo/src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ticket-tsx": () => import("/opt/build/repo/src/pages/ticket.tsx" /* webpackChunkName: "component---src-pages-ticket-tsx" */)
}

