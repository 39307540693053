/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import * as React from "react"
import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"

const bugsnagClient = bugsnag("ff1bbec409cdfc1a2ab66d1cfee76cce")
bugsnagClient.use(bugsnagReact, React)

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin("react")

const wrapRootElement = ({ element }) => (
  <ErrorBoundary>{element}</ErrorBoundary>
)

export default wrapRootElement
